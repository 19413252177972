<template>
	<div>
		<div>
			<el-card class="box-card">
<!--				<div slot="header" class="subjectTitle" style="line-height: 36px;">-->
<!--					<div class="subjectName">-->
<!--						<span style="font-size: 19px;">{{subjectId}}.</span>-->
<!--						<div v-html="questionBody.title" style="font-size: 19px;"></div>-->
<!--					</div>-->
<!--				</div>-->
<!--				<div style="margin-top: -20px">-->
<!--					<el-radio-group v-model="radio" :disabled="isDis">-->
<!--						<el-radio :label="item.prefix" v-for="(item,index) in questionBody.items" :key="item.prefix"-->
<!--							style="margin-top: 20px;font-size: 17px">-->
<!--							<div style="display: flex;font-size: 17px;">-->
<!--								{{item.prefix}}. &nbsp;&nbsp;&nbsp;&nbsp;-->
<!--								<div v-html="item.content" style="font-size: 17px;"></div>-->
<!--							</div>-->
<!--						</el-radio>-->
<!--					</el-radio-group>-->
<!--				</div>-->

				<div v-if="haveAnswer">
					<p class="content"><span>标准答案:</span>	<span v-html="questionBody.correct"></span></p>
<!--					<div style="display: flex;">-->
<!--						<span class="content"><span>结果:</span></span>-->
<!--						<el-tag size="mini" v-if="questionBody.answerInfo.doRight" type="success"-->
<!--							style="margin-top: 20px;">正确</el-tag>-->
<!--						<el-tag size="mini" v-if="!questionBody.answerInfo.doRight" type="danger"-->
<!--							style="margin-top: 20px;">错误</el-tag>-->
<!--					</div>-->
<!--					<p class="content"><span>得分:</span>{{questionBody.answerInfo.score}} / {{questionBody.score}}</p>-->
          <p class="content">
            <span>通过率:</span>60%
          </p>
          <p class="content">
						<span>得分率:</span>60%
					</p>
					<div style="display: flex;">
						<p class="content"><span>难度:</span></p>
						<el-rate v-model="questionBody.difficult" style="margin-top: 20px;" disabled=""></el-rate>
					</div>
					<p class="content"><span>解析:</span><span v-html="questionBody.analyze"></span></p>
				</div>
			</el-card>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			subjectId: {
				type: Number,
				default: 1
			},
			isDis: {
				type: Boolean,
				default: false
			},
			questionBody: {
				type: Object,
				default: () => ({})
			},
			haveAnswer: {
				type: Boolean,
				default: false
			},
			chooseItem: {
				type: String,
				default: ""
			}
		},
		mounted() {
			// console.log(this.questionBody)
			// console.log(this.haveAnswer)
			// if (this.haveAnswer) {
			// 	this.radio = this.questionBody.answerInfo.content
			// }
		},
		watch: {
			radio: {
				handler: function(n, o) {
					if (this.haveAnswer) {

					} else {
						if (this.radio) {
							// console.log(this.radio)
							// console.log(this.questionBody.id)
							this.$emit("changed", {
								id: this.subjectId,
								name: this.radio,
								questionId: this.questionBody.id,
								content: this.radio,
								contentArray: [],
								itemOrder: this.subjectId
							})
						} else {
							this.$emit("unchanged", {
								id: this.subjectId,
								name: this.radio
							})
						}
					}

				}
			}
		},
		data() {
			return {
				radio: "",
				value1: 4.5
			}
		}
	}
</script>

<style lang="scss" scoped>
	/deep/ .el-radio {
		display: flex;
		margin-top: 5px;
	}

	.subjectName {
		display: flex;
		-webkit-user-select:none;
		-moz-user-select:none;
		-ms-user-select:none;
		user-select:none;
		p {
			font-size: 16px;
		}
	}


	.content {
		font-size: 16px;
		margin-top: 20px;
		//color: #808080;
		color: #606266;
		display: flex;

		span {
			font-weight: 500;
			color: #000;
			line-height: 20px;
		}
	}
</style>
