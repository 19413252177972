<template>
	<div>
		<div>
			<el-card class="box-card">
<!--				<div slot="header" class="subjectTitle">-->
<!--					<div style="display: flex;" class="subjectName">-->
<!--						<span style="font-size: 19px;">{{subjectId}}.</span>-->
<!--						<div v-html="questionBody.title" style="font-size: 19px;"></div>-->
<!--					</div>-->
<!--				</div>-->
				<div>
          <div style="font-weight: 600;color: #000;line-height: 20px;float: left;margin-bottom: 10px"><p >标准答案:</p></div>
					<editor ref="pyEditer" v-model="content" @init="editorInit" lang="python" theme="eclipse"
						width="100%" height="500px" :options="{
					   enableBasicAutocompletion: true,
					   enableSnippets: true,
					   enableLiveAutocompletion: true,
					   autoHeightEnabled:true,
					   tabSize:2,
					   fontSize:15,
					   showPrintMargin:false
					 }"></editor>
				</div>
				<div v-if="haveAnswer">
<!--					<p class="content"><span>标准答案:</span><span v-html="questionBody.correct"></span></p>-->
          <p class="content">
            <span>通过率:</span>60%
          </p>
          <p class="content">
						<span>得分率:</span>60%
					</p>
					<div style="display: flex;">
						<p class="content"><span>难度:  </span></p>
						<el-rate v-model="questionBody.difficult" style="margin-top: 20px;" disabled=""></el-rate>
					</div>
					<p class="content"><span>解析:</span></p>
          <div  style="margin-bottom: 20px;margin-left:20px;text-align: left"><span v-html="questionBody.analyze"></span></div>
				</div>
			</el-card>
		</div>
	</div>
</template>

<script>
	import glbJson from '../utils/global_variable.js'

	export default {
		// components:{monaco},
		components: {
			editor: require('vue2-ace-editor')
		},
		props: {
			subjectId: {
				type: Number,
				default: 1
			},
			isDis: {
				type: Boolean,
				default: false
			},
			questionBody: {
				type: Object,
				default: () => ({})
			},
			haveAnswer: {
				type: Boolean,
				default: false
			},
			chooseItem: {
				type: String,
				default: ""
			}
		},
		mounted() {
			if (this.haveAnswer) {
				// console.log(this.questionBody)
				// this.questionBody.items.forEach((item, index) => {
				// 	this.content = this.chooseItem
				// this.content = this.questionBody.answerInfo.content
				this.content = this.questionBody.correct
				// })
			}
		},
		methods: {
			editorInit: function() {
				//初始化ace
				require("brace/ext/language_tools");
				require("brace/mode/python");
				require("brace/snippets/python");
				require("brace/theme/eclipse");
			}
		},
		watch: {
			content: {
				handler: function(n, o) {
					if (this.haveAnswer) {

					} else {
						// console.log(this.content)
						if (this.content == "") {
							this.$emit("unchanged", {
								id: this.subjectId,
								name: this.content
							})
						} else {
							this.$emit("changed", {
								id: this.subjectId,
								name: this.radio,
								questionId: this.questionBody.id,
								content: this.content,
								contentArray: [],
								itemOrder: this.subjectId
							})
						}
					}
				},
				deep: true
			}
		},
		data() {
			return {
				textarea: "",
				content: ""
			}
		}
	}
</script>

<style lang="scss" scoped>
	.subjectName {
		display: flex;
		-webkit-user-select:none;
		-moz-user-select:none;
		-ms-user-select:none;
		user-select:none;
		line-height: 36px;
		p {
			font-size: 16px;
		}
	}


	.content {
		font-size: 14px;
		margin-top: 20px;
		color: #808080;
		display: flex;

		span {
			font-weight: 600;
			color: #000;
			line-height: 20px;
		}
	}
</style>
