<template>
    <div class="testPaperCenterSty">
        <!-- 单选 -->
        <div class="contentSty">
            <div class="cardTitle">
                <div>
                    <i class="el-icon-s-flag"></i>
                    <span>
            试题编号: {{id}}
					</span>
                    <span>
              试题解析
          </span>
                </div>
                <el-button style="margin-right: 0px;margin-left: 20px" type="primary" icon="el-icon-refresh-left"
                           size="small" @click="toQuestionDetail">返回上一级
                </el-button>
            </div>
            <radioAnalysis v-if="(questionBody.questionType == 0) || (questionBody.questionType == 1) "
                           :subjectId="subjectId"
                           :questionBody="questionBody" :haveAnswer="true" :isDis="true">
            </radioAnalysis>
            <checkAnalysis v-if="questionBody.questionType == 2" :subjectId="subjectId" :questionBody="questionBody"
                           :haveAnswer="true" :isDis="true">
            </checkAnalysis>
            <shortAnswerAnalysis v-if="questionBody.questionType == 3" :subjectId="subjectId"
                                 :questionBody="questionBody"
                                 :haveAnswer="true" :isDis="true">
            </shortAnswerAnalysis>
            <programmingAnalysis v-if="questionBody.questionType == 4" :subjectId="subjectId"
                                 :questionBody="questionBody" :isDis="true" :haveAnswer="true">
            </programmingAnalysis>
            <programmingAnalysisMore v-if="questionBody.questionType == 5" :subjectId="subjectId"
                                     :questionBody="questionBody" :isDis="true" :haveAnswer="true">
            </programmingAnalysisMore>
        </div>
    </div>
</template>

<script>

    import radioAnalysis from "@/components/radioAnalysis.vue"
    import checkAnalysis from "@/components/checkAnalysis.vue"
    import shortAnswerAnalysis from "@/components/shortAnswerAnalysis.vue"
    import programmingAnalysis from "@/components/programmingAnalysis.vue"
    import programmingAnalysisMore from "@/components/programmingAnalysisMore.vue"
    import {
        getQuestionAnalysis
    } from "@/api/index.js"

    export default {
        filters: {
            formatQuestionType(type) {
                switch (type) {
                    case 0:
                        return "单选题"
                        break;
                    case 1:
                        return "判断题"
                        break;
                    case 2:
                        return "多选题"
                        break;
                    case 3:
                        return "简答题"
                        break;
                    case 4:
                        return "编程题"
                        break;
                    case 5:
                        return "填空题"
                        break;
                    default:
                        break;
                }
            }
        },
        props: {
            worngBookDetails: {
                type: Object,
                default: () => ({})
            },
            subjectId: {
                type: Number,
                default: 1
            }
        },
        components: {
            radioAnalysis,
            checkAnalysis,
            shortAnswerAnalysis,
            programmingAnalysis,
            programmingAnalysisMore
        },
        mounted() {
            this.id = this.$route.params.id
            // alert(this.$route.params.id)
            // alert(this.id)
            // let param = {
            //   id: this.id
            // }
            getQuestionAnalysis(this.id).then(res => {
                if (res.code === "0000") {
                    // this.questionReadVM = res.data.questionReadVM
                    // this.answerRequest = res.data.answerRequest
                    this.questionBody = res.data
                    // this.questionBody.answerInfo = res.data.answerRequest
                }
            }).catch(() => {
                this.$router.go(-1);
                // this.$router.push({
                //   path: '/'
                // })
            });
        },

        data() {
            return {
                id: null,
                value1: 4.5,
                questionReadVM: {},
                answerRequest: {},
                questionBody: {}
            }
        },
        methods: {
            toQuestionDetail() {
                this.$router.go(-1);
            },
        }
    }
</script>

<style lang="scss" scoped>
    .testPaperCenterSty {
        width: 1280px;
        margin: auto;
        margin-top: 20px;
        min-height: 300px;
        // background-color: #FFFFFF;
        position: relative;

        .cardTitle {
            background-color: #67C23A;
            height: 35px;
            padding-left: 30px;
            //padding-right: 30px;
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            justify-content: space-between;

            div:first-child {
                span {
                    line-height: 35px;
                    font-weight: 800;
                    color: #FFFFFF;
                }

                span:last-child {
                    margin-left: 10px;
                }
            }

            div:last-child {
                p {
                    padding: 0 5px;
                    line-height: 30px;
                    height: 30px;
                    background-color: #E6A23C;
                    font-weight: 800;
                }
            }
        }

        .contentSty {
            padding-bottom: 30px 30px;
            margin-top: 20px;

            .box-card {
                text-align: left;
                padding: 0 30px;
                // margin-top: 10px;

                .title {
                    font-size: 18px;
                    font-weight: 800;
                    line-height: 30px;
                }

                .content {
                    font-size: 14px;
                    margin-top: 20px;
                    color: #808080;

                    span {
                        font-weight: 600;
                        color: #000;
                    }
                }
            }
        }
    }
</style>
