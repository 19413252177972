<template>
  <div>
    <div>
      <el-card class="box-card">
<!--        <div slot="header" class="subjectTitle">-->
<!--          <div style="display: flex;" class="subjectName">-->
<!--            <span style="font-size: 19px;">{{subjectId}}.</span>-->
<!--            <div v-html="questionBody.title" style="font-size: 19px;"></div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div v-if="!haveAnswer" style="margin-top: 10px;" v-for="(item,index) in questionBody.tmpEnter" :key="index">-->
<!--          <div style="display: flex;">-->
<!--            <span>({{index + 1}}).</span>-->
<!--            &lt;!&ndash; <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="content" :disabled="isDis"> &ndash;&gt;-->
<!--            <el-input placeholder="请输入内容" v-model="item.value" :disabled="isDis"-->
<!--                      @input="inputValueChange($event)">-->
<!--            </el-input>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div v-if="haveAnswer" style="margin-top: 10px;" v-for="(item,index) in content" :key="index">-->
<!--          <div style="display: flex;">-->
<!--            <span>({{index + 1}}).</span>-->
<!--            &lt;!&ndash; <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="content" :disabled="isDis"> &ndash;&gt;-->
<!--            <el-input placeholder="请输入内容" v-model="item.value" :disabled="isDis"-->
<!--                      @input="inputValueChange($event)">-->
<!--            </el-input>-->
<!--          </div>-->
<!--        </div>-->
        <div v-if="haveAnswer">
          <p class="content" ><span>标准答案:</span>
            <span style="text-align: left"v-html="questionBody.correct"></span></p>
          <p class="content">
            <span>通过率:</span>60%
          </p>
          <p class="content">
            <span>得分率:</span>60%
          </p>
          <div style="display: flex;">
            <p class="content"><span>难度:</span></p>
            <el-rate v-model="questionBody.difficult" style="margin-top: 20px;" disabled=""></el-rate>
          </div>
          <p class="content"><span>解析:</span><span v-html="questionBody.analyze"></span></p>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import glbJson from '../utils/global_variable.js'

export default {
  // components:{monaco},
  components: {
    editor: require('vue2-ace-editor')
  },
  props: {
    subjectId: {
      type: Number,
      default: 1
    },
    isDis: {
      type: Boolean,
      default: false
    },
    questionBody: {
      type: Object,
      default: () => ({})
    },
    haveAnswer: {
      type: Boolean,
      default: false
    },
    chooseItem: {
      type: String,
      default: ""
    }
  },
  mounted() {
    if (this.haveAnswer) {

      // this.questionBody.items.forEach((item, index) => {
      // 	this.content = this.chooseItem


      // this.content = this.questionBody.answerInfo.content
      this.content = JSON.parse(questionBody.answerInfo.content)


      // })
    }
  },
  methods: {
    editorInit: function() {
      //初始化ace
      require("brace/ext/language_tools");
      require("brace/mode/python");
      require("brace/snippets/python");
      require("brace/theme/eclipse");
    },
    inputValueChange(e) {
      this.$forceUpdate();
      let flag = 1;
      this.questionBody.tmpEnter.forEach((item, index) => {
        if (item.value == "") {
          flag = 0
        }
      })
      if (flag == 1) {
        this.$emit("changed", {
          id: this.subjectId,
          name: this.radio,
          questionId: this.questionBody.id,
          content: JSON.stringify(this.questionBody.tmpEnter),
          contentArray: [],
          itemOrder: this.subjectId
        })
      }
      if (flag == 0) {
        this.$emit("unchanged", {
          id: this.subjectId,
          name: this.radio
        })
      }
    }
  },
  watch: {
    // questionBody:{
    // 	handler:function(n,o){
    // 		console.log(this.questionBody.enterNum)
    // 		let tmpEnter = []
    // 		for (let i = 0; i < this.questionBody.enterNum; i++) {
    // 			// Things[i]
    // 			let tmp = {id:i+1,value:""}
    // 			tmpEnter.push(tmp)
    // 		}
    // 		this.questionBody.tmpEnter = tmpEnter
    // 	}
    // },
    // questionBody:{
    // 	handler: function(n, o) {
    // 		console.log("!23")
    // 	},
    // 	deep:true,
    // 	immediate: true
    // },
    content: {
      handler: function(n, o) {
        if (this.haveAnswer) {

        } else {
          if (this.content == "") {
            this.$emit("unchanged", {
              id: this.subjectId,
              name: this.content
            })
          } else {
            this.$emit("changed", {
              id: this.subjectId,
              name: this.radio,
              questionId: this.questionBody.id,
              content: this.content,
              contentArray: [],
              itemOrder: this.subjectId
            })
          }
        }
      },
      deep: true
    }
  },
  data() {
    return {
      textarea: "",
      content: ""
    }
  }
}
</script>

<style lang="scss" scoped>
.subjectName {
  display: flex;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  line-height: 36px;
  p {
    font-size: 16px;
  }
}


.content {
  font-size: 14px;
  margin-top: 20px;
  color: #808080;
  display: flex;

  span {
    font-weight: 600;
    color: #000;
    line-height: 20px;
  }
}
</style>
