<template>
	<div>
		<div>
			<el-card class="box-card">
				<div slot="header" class="subjectTitle">
					<div style="display: flex;">
						<span style="font-size: 17px;">{{subjectId}}.</span>
						<p v-html="questionBody.title" style="font-size: 17px;"></p>
					</div>
				</div>
				<div style="margin-top: -30px">
					<el-checkbox-group v-model="checkList" :disabled="isDis">
						<el-checkbox :label="item.prefix" v-for="(item,index) in questionBody.items" :key="item.prefix"
							style="margin-top: 20px;">
							<div style="display: flex;margin-top: 5px;font-size: 16px;">
								{{item.prefix}}.
								<p v-html="item.content" style="font-size: 16px;"></p>
							</div>
						</el-checkbox>
					</el-checkbox-group>
				</div>

				<div v-if="haveAnswer">
					<p class="content"><span>标准答案: </span>{{questionBody.correct}}</p>
<!--					<div style="display: flex;">-->
<!--						<span class="content"><span>结果: </span></span>-->
<!--						<el-tag size="mini" v-if="questionBody.answerInfo.doRight" type="success"-->
<!--							style="margin-top: 20px;">-->
<!--							正确</el-tag>-->
<!--						<el-tag size="mini" v-if="!questionBody.answerInfo.doRight" type="danger"-->
<!--							style="margin-top: 20px;">-->
<!--							错误</el-tag>-->
<!--					</div>-->
<!--					<p class="content"><span>得分: </span>{{questionBody.answerInfo.score}} / {{questionBody.score}}</p>-->
          <p class="content">
            <span>通过率率:</span>0%
          </p>
          <p class="content">
						<span>得分率:</span>0%
					</p>
					<div style="display: flex;">
						<p class="content"><span>难度: </span></p>
						<el-rate v-model="questionBody.difficult" style="margin-top: 20px;" disabled=""></el-rate>
					</div>
					<p class="content"><span>解析: </span><span v-html="questionBody.analyze"></span></p>
				</div>
			</el-card>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			subjectId: {
				type: Number,
				default: 1
			},
			isDis: {
				type: Boolean,
				default: false
			},
			questionBody: {
				type: Object,
				default: () => ({})
			},
			haveAnswer: {
				type: Boolean,
				default: false
			},
			chooseItem: {
				type: String,
				default: ""
			}
		},
		mounted() {
			// console.log(this.questionBody)
			if (this.haveAnswer) {
				// console.log(this.questionBody)
				this.checkList = this.questionBody.answerInfo.contentArray
			}
		},
		watch: {
			checkList: {
				handler: function(n, o) {
					if (this.haveAnswer) {

					} else {
						// console.log(this.checkList)
						if (this.checkList.length != 0) {
							// console.log(this.subjectId)
							// this.$emit("changed", {id:this.subjectId,name:this.checkList})
							this.$emit("changed", {
								id: this.subjectId,
								name: this.checkList,
								questionId: this.questionBody.id,
								content: "",
								contentArray: this.checkList,
								itemOrder: this.subjectId
							})
						} else {
							// console.log(this.subjectId)
							this.$emit("unchanged", {
								id: this.subjectId,
								name: this.checkList
							})
						}
					}

				},
				deep: true
			}
		},
		data() {
			return {
				checkList: [],
				// subjectId:null
			}
		}
	}
</script>

<style lang="scss" scoped>
	.subjectName {
		display: flex;

		p {
			font-size: 16px;
		}
	}


	.content {
		font-size: 14px;
		margin-top: 20px;
		color: #808080;
		display: flex;

		span {
			font-weight: 600;
			color: #000;
			line-height: 20px;
		}
	}
</style>
