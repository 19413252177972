var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('el-card',{staticClass:"box-card"},[_c('div',[_c('div',{staticStyle:{"font-weight":"600","color":"#000","line-height":"20px","float":"left","margin-bottom":"10px"}},[_c('p',[_vm._v("标准答案:")])]),_c('editor',{ref:"pyEditer",attrs:{"lang":"python","theme":"eclipse","width":"100%","height":"500px","options":{
					   enableBasicAutocompletion: true,
					   enableSnippets: true,
					   enableLiveAutocompletion: true,
					   autoHeightEnabled:true,
					   tabSize:2,
					   fontSize:15,
					   showPrintMargin:false
					 }},on:{"init":_vm.editorInit},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],1),(_vm.haveAnswer)?_c('div',[_c('p',{staticClass:"content"},[_c('span',[_vm._v("通过率:")]),_vm._v("60% ")]),_c('p',{staticClass:"content"},[_c('span',[_vm._v("得分率:")]),_vm._v("60% ")]),_c('div',{staticStyle:{"display":"flex"}},[_c('p',{staticClass:"content"},[_c('span',[_vm._v("难度: ")])]),_c('el-rate',{staticStyle:{"margin-top":"20px"},attrs:{"disabled":""},model:{value:(_vm.questionBody.difficult),callback:function ($$v) {_vm.$set(_vm.questionBody, "difficult", $$v)},expression:"questionBody.difficult"}})],1),_c('p',{staticClass:"content"},[_c('span',[_vm._v("解析:")])]),_c('div',{staticStyle:{"margin-bottom":"20px","margin-left":"20px","text-align":"left"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.questionBody.analyze)}})])]):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }